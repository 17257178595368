import { StyledInput } from '@/components/styles'
import { CSSTransition } from 'react-transition-group'

export default function ({ type, error, value, placeholder, onChange, onClick, inputRef }) {
    return (
        <>
            <StyledInput
                type={type ? type : 'text'}
                placeholder={placeholder}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onClick={onClick}
                error={error}
                ref={inputRef}
            />
            <CSSTransition
                in={!!error?.length}
                timeout={{
                    appear: 0,
                    enter: 160,
                    exit: 160,
                }}
                classNames="on"
                unmountOnExit
            >
                <StyledInput.Error>{error}</StyledInput.Error>
            </CSSTransition>
        </>
    )
}
