import styled, {keyframes} from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Button = styled.button`
  position: relative;
  display: block;
  width: 100%;
  padding: 18px 24px;
  background: #252528;
  border-radius: 15px;
  color: #fff;
  
  &:disabled {
    background: #F6F7FA;
    color: #A6B0C1;
  }
  
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  outline: none;
  cursor: pointer;
  transition: 0.25s ease-out;
      
    &::after {
        content: '';
        position: absolute;
        right: 15px;
        top: calc(50% - 9px);
        width: 18px;
        height: 18px;
        border: 3px solid currentColor;
        border-left-color: transparent;
        border-radius: 50%;
        opacity: ${p => p.isLoading ? 1 : 0};
        transition: opacity 0.5s ease;
        pointer-events: none;
        animation: ${rotate} 1s linear infinite;
    }
`

export default Button
