import styled from 'styled-components'

const Title = styled.div`
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
`

export default Title
