import styled, { css } from 'styled-components'

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
`

Form.Inputs = styled.div`
    flex: 1;
`

export default Form
