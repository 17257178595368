import styled, { css } from 'styled-components'

export const inputStyles = () => css`
    position: relative;
    width: 100%;
    padding: 12px 0;
    background: #FFFFFF;
    border: none;
    font-weight: 500;
    font-size: 30px;
    line-height: 44px;
    outline: none;
`

const Input = styled.input`
    ${inputStyles}
`

Input.Error = styled.div`
    margin-top: 12px;
    font-weight: 600;
    font-size: 16px;
    color: #FF5C00;
    
    &.on-enter {
        opacity: 0;
        transform: translateY(-2px);
    }

    &.on-enter-active {
        opacity: 1;
        transform: none;
        transition: opacity 160ms, transform 160ms;
    }

    &.on-exit {
        opacity: 1;
        transform: none;
    }

    &.on-exit-active {
        opacity: 0;
        transform: translateY(-2px);
        transition: opacity 160ms, transform 160ms;
    }
`

export default Input
