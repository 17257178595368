import styled  from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    max-width: 450px;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
`

export default Container
