import styled from 'styled-components'

export default function Page({ children }) {
    return <StyledPage>{children}</StyledPage>
}

const StyledPage = styled.main`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 320px;
    padding: 32px 0;
`
