import React from 'react'
import Logo from '../assets/icons/logo.svg'
import Pedestrian from '../assets/icons/pedestrian.svg'
import Car from '../assets/icons/car.svg'
import Back from '../assets/icons/back.svg'

const icons = {
    logo: Logo,
    pedestrian: Pedestrian,
    car: Car,
    back: Back,
}

export default function ({ name, width, height }) {
    return icons[name] ? React.createElement(icons[name], { width, height }) : null
}
